.swiper-button-prev, .swiper-button-next {
  color: #ffffff;
  background-color: #FCA100;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
}

.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 1rem;
  font-weight: 600;
}

.swiper-button-prev {
  left: 5%;
}
.swiper-button-next {
  right: 5%;
}
